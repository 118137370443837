<template>
    <div class="container repair-container">

        <!-- Device details -->
        <label v-if="multiDevice">Select the device to book in</label>
        <label v-if="!multiDevice">Please check the device supplied matches the details below</label>

        <div v-if="order.devices.length">
            <div class="card card-body" v-for="deviceOption in order.devices" :key="deviceOption.reference" :hidden="device && deviceOption.reference !== device.reference">
                <div class="media">
                    <div class="media-left">
                        <img v-if="deviceOption.image" :src="deviceOption.image" class="img-2x">
                        <template v-if="!deviceOption.image">
                            <icon-mobile v-if="deviceOption.type === 'phone'" size="2x" class="icon-all-muted"></icon-mobile>
                            <icon-tablet v-if="deviceOption.type === 'tablet'" size="2x" class="icon-all-muted"></icon-tablet>
                        </template>
                    </div>
                    <div class="media-body">
                        <h5 class="mb-2">{{ deviceOption.model_title }}</h5>
                        <p class="mb-0 text-muted text-sm">IMEI: <template v-if="deviceOption.imei">{{ deviceOption.imei }}</template><template v-if="!deviceOption.imei">Unavailable</template></p>
<!--                        <p class="mb-0 mt-1 text-muted text-sm" v-if="device.board">Board: {{ device.board }}</p>-->
						<ul class="list-unstyled mb-0 mt-2 text-muted text-sm">
							<li v-for="(problem, i) in deviceOption.problems_selected" :key="i">
								{{ problem.title }}
							</li>
						</ul>
                    </div>
					<div class="media-right" v-if="multiDevice">
						<button class="btn continue-btn" v-if="!device" @click="setDevice(deviceOption)">Book in</button>
						<button class="btn continue-btn" v-if="device" @click="cancelSelectedDevice()">Cancel</button>
					</div>
                </div>
            </div>
        </div>

        <!-- Confirm correct device -->
		<template v-if="device">
			<label>Has the correct device been supplied?</label>
			<div class="card-grid grid-col-2">
                <div class="card-link-container">
					<router-link :to="{ name: 'dropOffDevice.confirmBooking'}" class="card card-select card-select-sm vertical" @click="setDeviceCorrect(true)" :class="{'active': deviceCorrect === true}">
						<icon-thumbs-up class="card-icon mb-2" size="lg"></icon-thumbs-up>
						Yes
					</router-link>
				</div>
				<div class="card-link-container">
					<div class="card card-select card-select-sm vertical" @click="deviceCorrect = false" :class="{'active': deviceCorrect === false}">
						<icon-thumbs-down class="card-icon mb-2" size="lg"></icon-thumbs-down>
						No
					</div>
				</div>
			</div>
		</template>

        <!-- Incorrect device supplied -->
        <div v-if="deviceCorrect === false">
            <label>What to do if the device is incorrect</label>
            <p>
                Please contact the WeFix customer support team to both update the device to the correct make, model, and colour, and also book in a new repair date. The customer may also be required to pay a call-out charge.
            </p>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'DeviceConfirm',

        data() {
            return {
                deviceCorrect: null
            }
        },

        mounted() {
            if (!this.order || !this.order.devices.length) {
                this.$router.push({name: `repairSearch`});

            } else if (!this.device && this.order.devices.length === 1) {
                this.setDevice(this.order.devices[0]);
            }
        },

        computed: {
            ...mapGetters(['device', 'order']),

            multiDevice() {
                return this.order.devices.length > 1;
            }
        },

        methods: {
            ...mapActions(['clearDevice', 'setDevice']),

            cancelSelectedDevice() {
                this.deviceCorrect = null;
                this.clearDevice();
            },

            setDeviceCorrect(status) {
                this.deviceCorrect = status;
            }
        }
    }
</script>

<style scoped>

</style>